let play_url = 'https://player.cxbd.world/player?url='
let play_orgin_url = 'https://player.cxbd.world/player?origin_url='
function timeToSeconds(timeString) {
    const timeParts = timeString.split(":");
    let minutes = 0;
    let seconds = 0;
    let hours = 0;

    if (timeParts.length === 2) {
        minutes = parseInt(timeParts[0], 10);
        seconds = parseInt(timeParts[1], 10);
    } else if (timeParts.length === 3) {
        hours = parseInt(timeParts[0], 10);
        minutes = parseInt(timeParts[1], 10);
        seconds = parseInt(timeParts[2], 10);
    }

    const totalSeconds = hours * 3600 + minutes * 60 + seconds;
    return totalSeconds;
}

async function checkURLAccessibility(url) {
    return fetch(url)
        .then(response => {
            if (response.ok) {
                // console.log("成功：", url);
                return true; // URL可访问
            } else {
                // console.log("失败：", url);
                return false; // URL不可访问
            }
        })
        .catch(() => {
            return false; // 发生错误，URL不可访问
        });
}

const moav = async (url1, time) => {
    'use strict';

    let lev = new Array();
    lev[0] = "_2160p"
    lev[1] = "_1080p"
    lev[2] = "_720p";
    lev[3] = "";

    var true_url;
    var urls = new Array();
    var flag = new Array(2);
    function find(str, cha, num) {
        var x = str.indexOf(cha);
        for (var i = 0; i < num; i++) {
            x = str.indexOf(cha, x + 1);
        }
        return x;
    }


    let s1;
    var wz1 = find(url1, '/', 3);
    var wz2 = find(url1, '/', 4);
    s1 = url1.slice(wz1 + 1, wz2);
    //alert(s1);

    let s2 = "0";
    if (s1.length > 3) {
        s2 = s1.replace(s1.substring(s1.length - 3), "000");
    }
    //alert(s2);


    var k = 0;
    while (k < 4) {
        var url2 = "https://moavcom.douyincontent.com/hls/contents/videos/" + s2 + "/" + s1 + "/" + s1 + lev[k] + ".mp4/";
        //alert(url2);
        urls.push(url2);
        k++;
    }


    let total = timeToSeconds(time) / 10;
    console.log("视频片段个数：", total);

    // 获得最佳url
    async function chooseUrl() {
        for (let i = 0; i < 4; i++) {
            let url = urls[i] + "cdn-1-v1-a1.ts\n";
            const res = await checkURLAccessibility(url);
            if (res) {
                true_url = urls[i];
                break;
            }
        }
    }


    //构造m3u8文件并写入
    async function construct_m3u8() {
        await chooseUrl();
        let data = "#EXTM3U\n#EXT-X-TARGETDURATION:10\n#EXT-X-ALLOW-CACHE:YES\n#EXT-X-PLAYLIST-TYPE:VOD\n#EXT-X-VERSION:3\n#EXT-X-MEDIA-SEQUENCE:1"
        for (let i = 1; i < total; i++) {
            data += "#EXTINF:10.000,\n";
            data += (true_url + "cdn-" + i + "-v1-a1.ts\n");
        }
        data += '#EXT-X-ENDLIST'
        console.log(data);

        var blob = new Blob([data], { type: "text/plain;" });
        let url = window.URL.createObjectURL(blob);
        console.log(url)
        return url
    }

    let url = await construct_m3u8()
    return url


};

const youhu = async (url1,time) => {
    'use strict';
    console.log("soav")
    var true_url;
    var urls = new Array();

    var lev = new Array();
    lev[0] = "_1080p"
    lev[1] = "_720p";
    lev[2] = "";

    function find(str, cha, num) {
        var x = str.indexOf(cha);
        for (var i = 0; i < num; i++) {
            x = str.indexOf(cha, x + 1);
        }
        return x;
    }
    let s1
    var wz1 = find(url1, '/', 3);
    var wz2 = find(url1, '/', 4);
    if(wz2==-1)wz2=url1.length;
    
    s1 = url1.slice(wz1 + 1, wz2);
    console.log(s1);

    let s2 = "0";
    if (s1.length > 3) {
        s2 = s1.replace(s1.substring(s1.length - 3), "000");
    }

    let k = 0;
    while (k < lev.length) {
        var url2 = "https://cdn2.keepclouds.com/hls/contents/videos/" + s2 + "/" + s1 + "/" + s1 + lev[k] + ".mp4/";
        // console.log('url2 :', url2);
        //alert(url2);
        urls.push(url2);
        k++;
    }

    k = 0;
    while (k < lev.length) {
        var url2 = "https://cdn1.keepclouds.com/hls/contents/videos/" + s2 + "/" + s1 + "/" + s1 + lev[k] + ".mp4/";
        // console.log('url2 :', url2);
        //alert(url2);
        urls.push(url2);
        k++;
    }

    let total = timeToSeconds(time) / 10;
    // console.log("视频片段个数：", total);

    // 获得最佳url
    async function chooseUrl() {
        for (let i = 0; i < urls.length; i++) {
            let url = urls[i] + "cdn-1-v1-a1.ts\n";
            const res = await checkURLAccessibility(url);
            if (res) {
                true_url = urls[i];
                // console.log('true_url :', true_url);
                break;
            }
        }
    }

    //构造m3u8文件并写入
    async function construct_m3u8() {
        await chooseUrl();
        let data = "#EXTM3U\n#EXT-X-TARGETDURATION:10\n#EXT-X-ALLOW-CACHE:YES\n#EXT-X-PLAYLIST-TYPE:VOD\n#EXT-X-VERSION:3\n#EXT-X-MEDIA-SEQUENCE:1"
        for (let i = 1; i < total; i++) {
            data += "#EXTINF:10.000,\n";
            data += (true_url + "cdn-" + i + "-v1-a1.ts\n");
        }
        data += '#EXT-X-ENDLIST'
        // console.log(data);

        var blob = new Blob([data], { type: "text/plain;" });
        let url = window.URL.createObjectURL(blob);
        console.log('url :', url);
        // url = play_url + url
        return url
    }

    let url = await construct_m3u8()
    return url

};
const _91rebao = async (url1, time) => {
    'use strict';

    console.log("into rebao");
    var lev = new Array();
    lev[0] = "_1080p";
    lev[1] = "_720p";
    lev[2] = "";

    var true_url;
    var urls = new Array();
    function find(str, cha, num) {
        var x = str.indexOf(cha);
        for (var i = 0; i < num; i++) {
            x = str.indexOf(cha, x + 1);
        }
        return x;
    }

    let s1
    var wz1 = find(url1, '/', 3);
    var wz2 = find(url1, '/', 4);
    s1 = url1.slice(wz1 + 1, wz2);
    //alert(s1);

    let s2 = "0";
    if (s1.length > 3) {
        s2 = s1.replace(s1.substring(s1.length - 3), "000");
    }
    //alert(s2);

    var k = 0;
    while (k < lev.length) {
        // var url2 = "https://91rbnet.douyincontent.com/hls/contents/videos/" + s2 + "/" + s1 + "/" + s1 + lev[k] + ".mp4/index.m3u8";
        var url2 = "https://91rbnet.gslb-al.com/hls/contents/videos/" + s2 + "/" + s1 + "/" + s1 + lev[k] + ".mp4/";
        //alert(url2);
        urls.push(url2);
        k++;
    }

    let total = timeToSeconds(time) / 10;
    // console.log("视频片段个数：", total);

    // 获得最佳url
    async function chooseUrl() {
        for (let i = 0; i < 4; i++) {
            let url = urls[i] + "cdn-1-v1-a1.ts\n";
            const res = await checkURLAccessibility(url);
            if (res) {
                true_url = urls[i];
                break;
            }
        }
    }


    //构造m3u8文件并写入
    async function construct_m3u8() {
        await chooseUrl();
        let data = "#EXTM3U\n#EXT-X-TARGETDURATION:10\n#EXT-X-ALLOW-CACHE:YES\n#EXT-X-PLAYLIST-TYPE:VOD\n#EXT-X-VERSION:3\n#EXT-X-MEDIA-SEQUENCE:1"
        for (let i = 1; i < total; i++) {
            data += "#EXTINF:10.000,\n";
            data += (true_url + "cdn-" + i + "-v1-a1.ts\n");
        }
        data += '#EXT-X-ENDLIST'
        // console.log(data);

        var blob = new Blob([data], { type: "text/plain;" });
        let url = window.URL.createObjectURL(blob);
        console.log('url :', url);
        // url = play_url + url
        return url
    }

    let url = await construct_m3u8()
    return url

};
const resao = async (url1) => {
    'use strict';

    var lev = new Array();
    lev[0] = "_1080p"
    lev[1] = "_720p";
    lev[2] = "";

    var true_url;
    var urls = new Array();
    function find(str, cha, num) {
        var x = str.indexOf(cha);
        for (var i = 0; i < num; i++) {
            x = str.indexOf(cha, x + 1);
        }
        return x;
    }

    let s1
    var wz1 = find(url1, '/', 3);
    var wz2 = find(url1, '/', 4);
    s1 = url1.slice(wz1 + 1, wz2);
    // alert(s1);

    let s2 = "0";
    if (s1.length > 3) {
        s2 = s1.replace(s1.substring(s1.length - 3), "000");
    }
    //alert(s2);


    var k = 0;
    while (k < lev.length) {
        var url2 = "https://resaocom.douyincontent.com/hls/contents/videos/" + s2 + "/" + s1 + "/" + s1 + lev[k] + ".mp4/index.m3u8";
        //alert(url2);
        urls.push(url2);
        k++;
    }

    // 获得最佳url
    async function chooseUrl() {
        for (let i = 0; i < urls.length; i++) {
            const res = await checkURLAccessibility(urls[i]);
            if (res) {
                true_url = urls[i];
                break;
            }
        }
    }

    await chooseUrl()
    let url = true_url
    return url


};

const avjb = async (url1, time) => {
    'use strict';

    var true_url;
    var urls = new Array();
    function find(str, cha, num) {
        var x = str.indexOf(cha);
        for (var i = 0; i < num; i++) {
            x = str.indexOf(cha, x + 1);
        }
        return x;
    }

    let s1
    var wz1 = find(url1, '/', 3);
    var wz2 = find(url1, '/', 4);
    s1 = url1.slice(wz1 + 1, wz2);
    //alert(s1);

    let s2 = "0";
    if (s1.length > 3) {
        s2 = s1.replace(s1.substring(s1.length - 3), "000");
    }
    //alert(s2);



    var url2 = "https://hls-api.cxbd.world/cdn1-avjb/contents/videos/" + s2 + "/" + s1 + "/index.m3u8";
    //alert(url2);
    urls.push(url2);

    // url2 = "https://hls-api.cxbd.site/cdn2-avjb/hls/contents/videos/" + s2 + "/" + s1 + "/index.m3u8";
    // urls.push(url2);

    // url2 = "https://hls-api.cxbd.site/cdn3-avjb/contents/videos/" + s2 + "/" + s1 + "/index.m3u8";
    // urls.push(url2);

    // url2 = "https://hls-api.cxbd.site/cdn4-avjb/contents/videos/" + s2 + "/" + s1 + "/index.m3u8";
    // urls.push(url2);

    // 获得最佳url
    async function chooseUrl() {
        for (let i = 0; i < 3; i++) {
            const res = await checkURLAccessibility(urls[i]);
            if (res) {
                true_url = urls[i];
                break;
            }
        }
    }

    await chooseUrl()

    // 服务器没有加cors,必须使用代理
    // async function construct_m3u8() {
    //     if (true_url) {
    //         let res = await fetch(true_url, { referrer: "" })
    //         let str = await res.text()

    //         let array = str.split('\n')
    //         let data = ''
    //         let host = true_url.replace('index.m3u8', '')
    //             .replace('https://hls-api.onrender.com/api/avjb-cdn3', 'https://d3ma0fqepvymei.cloudfront.net')
    //             .replace('https://hls-api.onrender.com/api/avjb-cdn2', 'https://tw.cvhlscdn.com')
    //             .replace('https://hls-api.onrender.com/api/avjb-cdn', 'https://j9.avstatic.com')
    //         for (let i = 0; i < array.length; i++) {
    //             if (i < 7) data += (array[i] + '\n')
    //             else {
    //                 if (i % 2 == 0) {
    //                     data += (array[i] + '\n')
    //                 } else {
    //                     data += (host + array[i] + '\n')
    //                 }
    //             }
    //         }
    //         console.log(data)
    //         var blob = new Blob([data], { type: "text/plain;" });
    //         let tmp_url = window.URL.createObjectURL(blob);
    //         let url = play_url + tmp_url
    //         // console.log(url)
    //         return url
    //     } else {
    //         return play_url
    //     }

    // }
    // let url = await construct_m3u8()


    // console.log("返回的url：", url)

    console.log('true_url :', true_url);
    return true_url
};

const _99re = (url1) => {
    'use strict';

    function find(str, cha, num) {
        var x = str.indexOf(cha);
        for (var i = 0; i < num; i++) {
            x = str.indexOf(cha, x + 1);
        }
        return x;
    }
    let s1
    var wz1 = find(url1, '/', 3);
    var wz2 = find(url1, '/', 4);
    s1 = url1.slice(wz1 + 1, wz2);
    console.log(s1);


    let url = "https://www.99b90.com/embed/" + s1;

    return url;
}

const parse = async (url, time = '', need_parse = true, only_url = false) => {
    // console.log('parse url :', url);
    if (!need_parse) return play_orgin_url + url;

    url = url.replace('http://','https://')
    // console.log('url :', url);

    if (url.startsWith("https://moav.com/")) {
        if (only_url) return await moav(url, time);
        else return play_url + await moav(url, time);
    } else if (url.startsWith("https://www.youhu.com/")) {
        if (only_url) return await youhu(url, time);
        return play_url + await youhu(url, time);
    } else if (url.startsWith("https://91rebao.com/") || url.startsWith("https://www.91rb.net/")) {
        if (only_url) return await _91rebao(url, time);
        return play_url + await _91rebao(url, time)
    } else if (url.startsWith("https://www.resao.com/")) {
        if (only_url) return await resao(url, time);
        else return play_url + await resao(url)
    } else if (url.startsWith("https://99b90.com/")) {
        return _99re(url)
    } else if (url.startsWith("https://avjb.com/")) {
        if (only_url) return await avjb(url, time);
        else return play_url + await avjb(url, time)
    }
}
export default parse





